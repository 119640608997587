<template>
    <div>
        <!-- <p></p> -->
        <!-- <p class="title_bg"></p> -->
        <ul class="word_list flex">
            <li @click="routePush(item.path)" v-if="$route.meta != item.title" v-for="(item, index) in list" :key="index">
                <img :src="item.imgUrl" alt="">
                <p>{{ item.title }}</p>
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    name: '',
    created() { },
    mounted() {
    },
    data() {
        return {
            list: [
                {
                    id: 13,
                    imgUrl: require('@/assets/ai/13.png'),
                    title: 'AI绘画',
                    path: '/paint'
                },
                {
                    id: 15,
                    imgUrl: require('@/assets/ai/15.png'),
                    title: '黑白图像上色',
                    path: '/blackWhite'
                },
                {
                    id: 16,
                    imgUrl: require('@/assets/ai/16.png'),
                    title: '图片风格转换',
                    path: '/transImg'
                },
                // {
                //     id: 17,
                //     imgUrl: require('@/assets/ai/17.png'),
                //     title: '人物头像动漫化',
                //     path:'/cartoon'
                // },
                {
                    id: 22,
                    imgUrl: require('@/assets/ai/22.png'),
                    title: '图片背景生成',
                    path: '/image_matting'
                },
                {
                    id: 23,
                    imgUrl: require('@/assets/ai/23.png'),
                    title: '一键抠图',
                    path: '/change_back'
                },
                {
                    id: 18,
                    imgUrl: require('@/assets/ai/18.png'),
                    title: '律师函',
                    path: '/lawyer'
                },
                // {
                //     id: 19,
                //     imgUrl: require('@/assets/ai/19.png'),
                //     title: '合同审查',
                // },
                {
                    id: 20,
                    imgUrl: require('@/assets/ai/20.png'),
                    title: '版权侵权告知书',
                    path: '/tort'
                },
                {
                    id: 21,
                    imgUrl: require('@/assets/ai/21.png'),
                    title: '起诉书',
                    path: '/prosecute'
                },
                {
                    id: 24,
                    imgUrl: require('@/assets/ai/24.png'),
                    title: 'AI智能海报',
                    path: '/placard',
                },

            ],
            fullscreenLoading: false
        }
    },
    methods: {
        routePush(path) {
            this.fullscreenLoading = true;
            this.$router.push({
                path
            })
            this.fullscreenLoading = false;

            // console.log(item);

        }
    },
}
</script>

<style lang=scss scoped>
.title_bg {
    margin: 0 auto;
    width: 150px;
    height: 50px;
    background: url('~@/assets/ai/nav.png')no-repeat;
    background-size: 100%;
}

.word_list {
    // min-width: 500px;
    max-width: 410px;
    // max-height:550px;
    max-height: 550px;
    margin: 50px 0;
    //justify-content: space-between;
    // justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 120px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 1.25rem;
        border: 0.25rem solid rgba(255, 255, 255, 1);
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        margin-bottom: 20px;
        margin-right: 20px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:hover {
            background: rgba(196, 227, 255, 1);
        }

        img {
            width: 40%;
            display: block;
            margin: 0 auto;
        }

        p {
            margin-top: .5rem;
            font-size: 1.2rem;
            color: rgba(51, 51, 51, 1);
        }
    }
}
</style>