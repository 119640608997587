<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="header flex">
			<div class="logo_box flex">
				<img src="@/assets/user/logo.png" alt="" @click="$router.push('/')" />
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/user/index' }">个人中心</el-breadcrumb-item>
					<el-breadcrumb-item><a>{{ $route.matched[1].meta }}</a></el-breadcrumb-item>
					<el-breadcrumb-item v-if="$route.matched[2] && !$route.matched[2].meta"><a>{{
		$route.matched[2].meta }}</a></el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="user_box flex" v-show="user_info">
				<p class="userName">用户名: {{ user_info.username }}</p>
				<span class="lv_icon vip" v-show="user_info.group_id == 2"></span>
				<span class="lv_icon svip" v-show="user_info.group_id == 3"></span>
				<p class="New_message" @click="childRoute({
		title: '消息中心'
	})">最新消息[<span>{{ user_info.unread_notice_num }}</span>]</p>
				<button class="quit" @click="quit">退出</button>
			</div>
		</div>
		<div class="flex box">
			<ul class="list">
				<li :class="($store.state.activeTitle == item.title && $store.state.activeTitle == '首页')
		? 'activeHover'
		: 'li_1'
		" v-for="(item, index) in $store.state.navList" :key="index">
					<p class="flex" @click.prevent="ShowFn(item)">
						<img :src="item.imgUrl" alt="" class="img1" />
						<img :src="item.imgUrl2" alt="" class="img2" />
						<span>{{ item.title }}</span>
					</p>
					<i @click.prevent="ShowFn(item)" v-show="item.children" class="select" :style="$store.state.activeTitle == item.title && item.selectStatus
		? 'transform:rotate(180deg)'
		: 'transform:rotate(0deg)'
		"></i>
					<ul :class="($store.state.activeTitle == item.title &&
		$store.state.activeTitle != '首页' &&
		item.selectStatus) ||
		($store.state.activeTitle == item.title && item.selectStatus) ? 'activeUl'
		: 'children_ul'
		">
						<li :class="$store.state.ActiveChild == itam.title ? 'activeHover' : ''"
							@click.stop="childRoute(itam)" v-for="(itam, index) in item.children" :key="index">
							{{ itam.title }}
							<el-badge :value="itam.order_status_total_num" class="item"
								v-if="(itam.id == 'order' && itam.order_status_total_num != 0)" :max="99">
							</el-badge>
							<el-badge :value="itam.package_status_total_num" class="item"
								v-if="(itam.id == 'package' && itam.package_status_total_num != 0)" :max="99">
							</el-badge>
							<el-badge :value="itam.draft_status_total_num" class="item"
								v-if="(itam.id == 'draft' && itam.draft_status_total_num != 0)" :max="99">
							</el-badge>
						</li>
					</ul>
				</li>
			</ul>
			<div class="router_view">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { goAi } from '@/util/goAi'
export default {
	name: '',
	created() {
		this.getUserInfo()

	},
	computed: {
		...mapState(['navList', 'activeTitle', 'ActiveChild', 'FillForm', 'pubNav', 'mediaOrder', 'manuscript', 'packOrder']),
		// 监听vuex中的mediaOrder中的数据是否发生改变,如果发生改变则需要重新替换左侧导航栏中的提示条数
		mediaOrderStatus() {
			return this.mediaOrder
		},
		packOrderStatus() {
			return this.packOrder
		},
		manuscriptStatus() {
			return this.manuscript
		}
	},
	watch: {
		mediaOrderStatus: {
			handler(newVal) {
				sessionStorage.setItem('vuex', JSON.stringify(this.$store.state))
				this.getOrderNum(this.mediaOrder.category_id)
			},
			deep: true,
		},
		packOrderStatus: {
			handler(newVal) {
				sessionStorage.setItem('vuex', JSON.stringify(this.$store.state))
				this.getOrderNum()

			},
			deep: true,
		},
		manuscriptStatus: {
			handler(newVal) {
				sessionStorage.setItem('vuex', JSON.stringify(this.$store.state))
				this.getOrderNum()

			},
			deep: true,
		}
	},
	mounted() {
		this.fullscreenLoading = true
		// 轮询查询次数
		// this.getOrderNum()

		this.timer = setInterval(this.getOrderNum(), 60000)
		this.fullscreenLoading = false
		if (localStorage.getItem('user_info')) {
			this.user_info = JSON.parse(localStorage.getItem('user_info'))
		}
		// console.log('刷新数据了:', this.$user_info);
	},
	data() {
		return {
			fullscreenLoading: false,
			user_info: this.$util.getLocalStorage('user_info'),
			timer: undefined,
		}
	},
	methods: {
		// 导航切换
		ShowFn(item) {
			this.$store.state.activeTitle = item.title
			// this.$store.commit('childTab', item.title)
			if (item.selectStatus != null) {
				item.selectStatus = !item.selectStatus
			}
			for (let i = 0; i < this.$store.state.navList.length; i++) {
				if (
					this.$store.state.navList[i].title != this.$store.state.activeTitle &&
					this.$store.state.navList[i].selectStatus == true
				) {
					this.$store.state.navList[i].selectStatus = false
				}
			}
			if (this.$store.state.activeTitle == '首页') {
				console.log(this.$store.state.activeTitle);
				this.$store.state.ActiveChild = ''
				console.log(this.$store.state.ActiveChild);
				this.$store.commit('childTab', '')
				this.$router.push({
					path: '/user/index',
				})
			}

			// console.log(item);
		},
		childRoute(itma) {
			this.$store.state.ActiveChild = itma.title
			console.log(this.$store.state.ActiveChild, 'userCheck');
			this.$store.state.fillActive = true
			console.log(itma);
			this.$store.commit('childTab', itma.title)
			// 我的媒介导航栏选中
			switch (itma.title) {
				case '媒介套餐':
					this.$router.push({
						path: '/user/SetMenu/SetMenuList',
					})
					break;
				case '媒介资源':
					this.$router.push({
						path: '/user/userMedia/News',
					})
					break;
				case '一言一文':
					this.curlPost('/file/adduser', {
						nickname: this.$user_info.username,
						mobile: this.$user_info.mobile,
						uid: this.$user_info.id,
					}).then(res => {
						console.log(res);
						if (res.data.code) {

							this.$router.push({
								path: '/wordNav',
							})

						} else {
							this.$message({
								type: 'info',
								message: res.data.msg
							});
						}
					})
					break;
				case '享视界':
					this.goAiNav()
					break;
				case '资讯门户发布':
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '自媒体发布':
					this.$store.commit('childTab', '自媒体发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '纸媒介发布':
					this.$store.commit('childTab', '纸媒介发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '公众号发布':
					this.$store.commit('childTab', '公众号发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '微博发布':
					this.$store.commit('childTab', '微博发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '小红书发布':
					this.$store.commit('childTab', '小红书发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '短视频发布':
					this.$store.commit('childTab', '短视频发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '套餐发布':
					this.$store.commit('childTab', '套餐发布')
					this.resetForm()
					this.$router.push({
						path: '/user/SetMenu/fill?id=' + itma.id,
					})
					break;
				case '草稿箱':
					this.$store.commit('childTab', '草稿箱')
					this.$store.state.shopObj = []
					this.$router.push({
						path: '/user/Draft',
					})
					break;
				case '媒介订单':
					this.$store.state.mediaOrder.category_id = 1
					this.$router.push({
						path: '/user/MediaOrder',
					})
					break;
				case '套餐订单':
					this.$router.push({
						path: '/user/PackageOrder',
					})
					break;
				case '我的工单':
					this.$router.push({
						path: '/user/MyOrder',
					})
					break;
				case 'AI记录':
					this.$router.push({
						path: '/user/Myword',
					})
					break;
				case '我的资源':
					this.$store.state.pubNav = 1
					this.$router.push({
						path: '/user/PublishingMedia/pubNews',
					})
					break
				case '我的收稿':
					this.$router.push({
						path: '/user/Manuscript',
					})
					break;
				case '售后记录':
					this.$router.push({
						path: '/user/AfterSale',
					})
					break;
				case '在线充值':
					this.$router.push({
						path: '/user/refill',
					})
					break;
				case '提现管理':
					this.$router.push({
						path: '/user/Withdraw',
					})
					break;
				case '账单细明':
					this.$router.push({
						path: '/user/AccountDetails',
					})
					break;
				case '发票列表':
					this.$router.push({
						path: '/user/Invoice',
					})
					break;
				case '申请发票':
					this.$router.push({
						path: '/user/ApplyForInvoice/ApplyForInvoiceData',
					})
					break;
				case '钱包转换':
					this.$router.push({
						path: '/user/WalletConversion',
					})
					break;
				case '消息中心':
					this.$router.push({
						path: '/user/MessageCenter',
					})
					break;
				case '资料密码':
					this.$router.push({
						path: '/user/Resource',
					})
					break;
				case '企业认证':
					this.$router.push({
						path: '/user/Enterprise',
					})
					break;
				case '平台帮助':
					this.$router.push({
						path: '/user/Help',
					})
					break;
				default:
					break
			}
		},
		// 退出
		quit() {
			this.$confirm('确定退出吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlPost('/api/user/logout').then(res => {
					if (res.data.code) {
						this.$message({
							offset: 60,
							type: 'success',
							message: res.data.msg
						});
						this.$util.setLocalStorage('user_info', res.data.data, 200) // 写入local 
						localStorage.removeItem('user_info')	// 清楚local
						sessionStorage.clear()
						this.$router.push('/')					// 跳转首页
					}
				})

			}).catch(() => {
				// this.$message({
				// 	type: 'info',
				// 	message: '已取消退出登录'
				// });
			});
		},
		getOrderNum(id) {
			let data = {}
			if (id) {
				data.category_id = id
			}
			this.curlGet('api/users/order/num', data).then(res => {
				if (res.data.code) {
					// this.draft_status_num = res.data.data
					// ['draft_status_total_num']
					this.navList.forEach((item, index) => {
						if (item.children) {
							this.navList[index].children.forEach((el, i) => {
								if (el.id == 'order') {
									el.order_status_total_num = res.data.data.order_status_total_num
								}
								if (el.id == 'package') {
									el.package_status_total_num = res.data.data.package_status_total_num
								}
								if (el.id == 'draft') {
									el.draft_status_total_num = res.data.data.draft_status_total_num
								}

							})
						}
					});

					// console.log('draft_status_num:', this.draft_status_num);
				}
			})
		},
		// 重置填写稿件中的内容
		resetForm() {
			this.$store.state.FillForm = {
				cover_image: '', //封面
				title: '',
				content: '', //编译器内容
				time: '', //时间选择器
				file: '',
				url: '',
				video_url: '',
				original_url: '', //原文链接
				remark: '',
				medium_ids: '',
				package_ids: '',
			}
			this.$store.state.shopObj = []

		},
		// 去功能选择页面
		async goAiNav() {
			const aiStatus = await goAi()
			console.log('aiStatus', aiStatus);
			if (aiStatus == 'ok') {
				this.$router.push('/aiNav')
			} else {
				return this.$store.state.loginShow = true
			}
		},

	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
}
</script>

<style lang=scss scoped>
@import '@/scss/userNav';
</style>