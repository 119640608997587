<template>
	<div class="form_ct_box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="flex cofirm_box">
			<button class="cancel" @click="$router.push('/user/PublishingMedia/pubNews')">
				取消
			</button>
			<button v-if="!$route.query.id" class="affirm" @click="addMedia">
				确认
			</button>
			<button v-else class="affirm" @click="updateFn">修改</button>
		</div>
		<div class="flex ct_box">
			<div class="ct_left_box">
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">门户类型：</p>
					<el-select v-model="form.gateway.title" @change="gatewayFn" placeholder="请选择">
						<el-option v-for="(item, index) in gatewayList" :key="index" :value="item.title">
						</el-option>
					</el-select>
					<p class="add_title" v-show="!$route.query.id" @click="addbox = true">
						还没有门户?,立即添加
					</p>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">媒介名称：</p>
					<el-input v-model="form.title" placeholder="请输入媒介名称"></el-input>
				</div>
				<upLoadImg title="媒介logo" :imgUrl="form.logo_reveal" @getImg="getLogo" />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">行业类型：</p>
					<el-select v-model="form.platform.title" @change="plat" placeholder="请选择行业类型">
						<el-option v-for="(item, index) in platList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">所属区域：</p>
					<el-select v-model="form.area.title" @change="areaFn" placeholder="请选择所属区域">
						<el-option v-for="(item, index) in areaList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">媒介价：</p>
					<el-input onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
						oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
						v-model="form.price" placeholder="请输入价格"></el-input>
				</div>
				<!-- 电脑权重 -->
				<weight weightName="电脑权重" :weight="form.weight" @weightFn="pcWeightFn" />
				<!-- 移动权重 -->
				<weight weightName="移动权重" :m_weight="form.m_weight" @weightFn="mWeightFn" />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">案例链接：</p>
					<el-input v-model="form.case_url" placeholder="请输入http:/或https:/完整地址"></el-input>
				</div>
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">截稿时间：</p>
					<el-select v-model="form.timer.title" @change="timerFn" placeholder="选择截稿时间">
						<el-option v-for="(item, index) in timerList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div> -->
			</div>
			<div class="ct_right_box">
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">收录效果：</p>
					<el-radio v-model="form.includeType" :label="0">不限</el-radio>
					<el-radio v-model="form.includeType" :label="1">包网页收录</el-radio>
					<el-radio v-model="form.includeType" :label="2">网页收录</el-radio>
					<el-radio v-model="form.includeType" :label="3">包百度新闻源收录</el-radio>
					<el-radio v-model="form.includeType" :label="4">百度新闻源收录</el-radio>
				</div>
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">出稿速度：</p>
					<el-radio v-model="form.draft_speed" :label="0">暂无</el-radio>
					<el-radio v-model="form.draft_speed" :label="1">1小时</el-radio>
					<el-radio v-model="form.draft_speed" :label="2">2小时</el-radio>
					<el-radio v-model="form.draft_speed" :label="3">3小时</el-radio>
					<el-radio v-model="form.draft_speed" :label="4">4小时</el-radio>
					<el-radio v-model="form.draft_speed" :label="5">当天</el-radio>
					<el-radio v-model="form.draft_speed" :label="6">次日</el-radio>
				</div> -->
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发联系方式：</p>
					<el-radio v-model="form.is_pub_contact" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_contact" :label="1">是</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发内链：</p>
					<el-radio v-model="form.is_pub_link" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_link" :label="1">是</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否官方：</p>
					<el-radio v-model="form.is_official" :label="0">否</el-radio>
					<el-radio v-model="form.is_official" :label="1">是</el-radio>
				</div>
				<specialText />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否为焦点图：</p>
					<el-radio v-model="form.is_pub_image" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_image" :label="1">是 </el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">可加视频：</p>
					<el-radio v-model="form.is_pub_video" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_video" :label="1">是 </el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">周末能否发稿：</p>
					<el-radio v-model="form.is_pub_weekend" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_weekend" :label="1">是 </el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否为文字链：</p>
					<el-radio v-model="form.is_pub_url" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_url" :label="1">是 </el-radio>
				</div>
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p3">权重：</p>
					<el-input v-model="form.weight" placeholder="请输入权重"></el-input>
				</div> -->

				<upLoadImg title="文字链截图" :imgUrl="form.pub_url_image_reveal" @getImg="getImg" />
				<div class="flex">
					<p class="title_p2">备注：</p>
					<el-input type="textarea" :rows="2" placeholder="有修改或其他问题请填写备注，免经济纠纷" v-model="form.remark">
					</el-input>
				</div>
				<p @click="mediumUpload" class="multiple">
					您有多个媒体?请点击此处
				</p>
			</div>
		</div>
		<el-dialog title="添加平台" :append-to-body="true" :visible.sync="addbox" :show-close="false">
			<el-row :gutter="20">
				<el-col :span="2.8">
					<div class="grid-content bg-purple">
						<p class="gateway_title">平台名称:</p>
					</div>
				</el-col>
				<el-col :span="18.2">
					<div class="grid-content bg-purple">
						<el-input v-model="gatewayForm.gatewayTitle" placeholder="请输入名称"></el-input>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="2.8">
					<div class="grid-content bg-purple">
						<p class="gateway_title">平台链接:</p>
					</div>
				</el-col>
				<el-col :span="18.2">
					<div class="grid-content bg-purple">
						<el-input v-model="gatewayForm.gatewayUrl" placeholder="请输入链接"></el-input>
					</div>
				</el-col>
				<div class="sub_box flex">
					<button class="sub" @click="addPlat">提交</button>
				</div>
			</el-row>
		</el-dialog>
		<!-- 批量上传 -->
		<el-dialog title="批量上传媒体" width="35%" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
			<div style="justify-content: center;align-items: center;text-align: center;" class="flex">
				<el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
					:on-success="Successxlsx" :before-remove="handleRemove">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">点击上传</div>
				</el-upload>
			</div>

			<div class="flex" style="justify-content: center;margin-top: 1rem;">
				<a class="multiple_a" target="_blank" href="/medium.xlsx">下载模板</a>

				<button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import weight from '@/components/weight.vue';
export default {
	computed: {
		...mapState(['active'])
	},
	components: {
		weight,
	},
	created() {
		this.curlGet('/api/medium/portal', {
			// category_id: this.$store.state.active
		}).then((res) => {
			if (res.data.code) {
				if (res.data.code) {
					for (const i in res.data.data[this.$store.state.active]) {
						this.gatewayList.push({
							id: i,
							title: res.data.data[this.$store.state.active][i],
						})
					}
				}
			}
		})
		// 请求行业类型列表
		this.curlGet('/api/medium/industry').then((res) => {
			if (res.data.code) {
				// console.log(this.$store.state.active);
				for (const i in res.data.data[this.$store.state.active]) {
					this.platList.push({
						id: i,
						title: res.data.data[this.$store.state.active][i],
					})
				}
			}
		})
		for (let i = 10; i < 25; i++) {
			this.timerList.push({
				id: i,
				title: `${i}:00`,
			})
		}
		/*--------------如果是从编辑过来的 则查数据----------------*/
		if (this.$route.query.id) {
			this.curlGet('/api/users/medium/detail', {
				id: this.$route.query.id,
			}).then((res) => {
				if (res.data.code) {
					let data = res.data.data
					this.gatewayList.forEach((item, index) => {
						if (data.portal_id == item.id) {
							this.form.gateway = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.platList.forEach((item, index) => {
						if (data.industry_classify_id == item.id) {
							this.form.platform = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.areaList.forEach((item, index) => {
						if (data.area_id == item.id) {
							this.form.area = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.timerList.forEach((item, index) => {
						if (data.stop_draft_time == item.id) {
							this.form.timer = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.form.title = data.title //标题
					this.form.logo = data.logo //展示logo
					this.form.logo_reveal = data.logo // 向后端发送logo
					this.form.price = data.medium_price //价格
					this.form.case_url = data.case_url //案例链接
					this.form.pub_url_image = data.pub_url_image //文字链截图
					this.form.pub_url_image_reveal = data.pub_url_image //文字链截图
					this.form.includeType = data.inlet_category //收录类型
					this.form.draft_speed = data.draft_speed // 出稿速度
					this.form.is_pub_contact = data.is_pub_contact //可发联系方式
					this.form.is_pub_weekend = data.is_pub_weekend
					this.form.is_pub_link = data.is_pub_link //可发内链
					this.form.is_pub_url = data.is_pub_url //是否为文字链
					this.form.is_official = data.is_official
					this.form.weight = data.weight //pc权重
					this.form.m_weight = data.m_weight //移动权重
					this.form.remark = data.remark //备注
					this.form.is_pub_video = data.is_pub_video //可加视频
					this.form.is_pub_image = data.is_pub_image
				}
			})
		}
	},
	mounted() {
		this.$store.state.active = 1
		this.curlGet('/api/area/pid_list').then((res) => {
			if (res.data.code) {
				for (const i in res.data.data) {
					this.areaList.push({
						id: i,
						title: res.data.data[i],
					})
				}
			}
		})

	},
	data() {
		return {
			timerList: [], //截稿时间类型
			gatewayList: [], //门户类型
			platList: [], //行业类型
			areaList: [], //地区类型
			value: '',
			dialogImageUrl: '',
			imageUrl: '',
			dialogVisible: false,
			form: {
				logo: '', //logo
				logo_reveal: '', //logo展示
				gateway: {}, //门户类型
				platform: {}, //行业
				includeType: 0, //收录类型
				draft_speed: 0, //出稿速度
				is_pub_contact: 0, //可否加联系方式
				is_pub_weekend: 0, //周末可发稿
				is_pub_image: 0, //可加配图
				is_pub_video: 0, //可加视频
				pub_url_image: '', //文字链截图
				pub_url_image_reveal: '', //文字链截图
				is_pub_link: 0,//可发内链
				title: '', //媒介名称
				area: {}, //地址
				timer: {}, //截稿时间
				case_url: '',
				is_official: 0,//是否官方
				price: undefined, //价格
				remark: '', // 备注
				is_pub_url: 0, //链接
				weight: 0, //pc权重
				m_weight: 0, // 移动权重
				mediaUrl: ''
			},
			headerObj: {
				token: this.$user_info.token,
			},
			fullscreenLoading: false, //加载
			addbox: false,
			gatewayForm: {
				gatewayTitle: '',
				gatewayUrl: '',
			},
			mediumBox: false,

		}
	},
	methods: {
		getImg(url,fullurl) {
			this.form.pub_url_image = url
			this.form.pub_url_image_reveal = fullurl
		},
		getLogo(url, fullurl) {
			this.form.logo = url
			this.form.logo_reveal = fullurl
		},
		// 获取截稿时间id
		timerFn(val) {
			let resultArr = this.timerList.filter((item) => {
				return item.title === val
			})
			this.form.timer = resultArr[0]
			console.log(this.form.timer)
		},
		// 批量上传媒体
		Successxlsx(res, file) {
			console.log(res, file)
			this.form.mediaUrl = res.data.url
		},
		upLoadXlsx() {
			this.curlGet('/api/users/medium/import', {
				file: this.form.mediaUrl
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					this.$message({
						message: '上传成功,待审核',
						type: 'success'
					});
					this.form.mediaUrl = ''
					return this.mediumBox = false
				} else {
					this.$message.error('上传失败');
				}
			})
		},
		beforeMove() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.imageUrl = ''
					this.$message({
						type: 'success',
						message: '已删除',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		gatewayFn(val) {
			let resultArr = this.gatewayList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.gateway = resultArr[0]
		},
		// 获取行业id
		plat(val) {
			let resultArr = this.platList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.platform = resultArr[0]
		},
		// 获取地区id
		areaFn(val) {
			let resultArr = this.areaList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.area = resultArr[0]
		},
		pcWeightFn(id) {
			this.form.weight = id
		},
		mWeightFn(id) {
			this.form.m_weight = id
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.form.mediaUrl = ''
		},
		// 添加媒体
		addMedia() {
			this.$confirm('确认提交吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/medium/add', {
						category_id: this.$store.state.active, // 导航id
						portal_id: this.form.gateway.id, // 门户(所属平台)类型
						title: this.form.title, // 标题
						medium_price: this.form.price, // 价格
						case_url: this.form.case_url, // 案例链接
						is_pub_link: this.form.is_pub_link, //可发链接
						industry_classify_id: this.form.platform.id, //媒体行业类型
						inlet_category: this.form.includeType, //收录效果
						area_id: this.form.area.id, //地区
						draft_speed: this.form.draft_speed, // 出稿速度
						weight: this.form.weight, // 权重
						m_weight: this.form.m_weight, // 移动权重
						is_pub_contact: this.form.is_pub_contact, //可发联系方式
						remark: this.form.remark, //备注
						logo: this.form.logo, //logo
						is_official: this.form.is_official, // 是否官方认证
						is_pub_weekend: this.form.is_pub_weekend, //周末可发稿
						is_pub_image: this.form.is_pub_image, //是否可加配图
						is_pub_video: this.form.is_pub_video, //可加视频
						stop_draft_time: this.form.timer.id, //截稿时间
						pub_url_image: this.form.pub_url_image, //文字链截图

					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: res.data.msg,
							})
							return this.$router.push('/user/PublishingMedia/pubNews')
						} else {
							return this.$message({
								type: 'warning',
								message: res.data.msg,
							})
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 修改媒体
		updateFn() {
			console.log(this.form.case_url);
			this.$confirm('确认修改吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.curlPost('/api/users/medium/add', {
					id: this.$route.query.id,
					category_id: this.$store.state.active, // 导航id
					portal_id: this.form.gateway.id, // 门户(所属平台)类型
					title: this.form.title, // 标题
					medium_price: this.form.price, // 价格
					case_url: this.form.case_url, // 案例链接
					is_pub_link: this.form.is_pub_link, //可发链接
					industry_classify_id: this.form.platform.id, //媒体行业类型
					inlet_category: this.form.includeType, //收录效果
					area_id: this.form.area.id, //地区
					draft_speed: this.form.draft_speed, // 出稿速度
					weight: this.form.weight, // 权重
					m_weight: this.form.m_weight, // 移动权重
					is_pub_contact: this.form.is_pub_contact, //可发联系方式
					remark: this.form.remark, //备注
					logo: this.dialogImageUrl, //logo
					is_official: this.form.is_official, // 是否官方认证
					is_pub_weekend: this.form.is_pub_weekend, //周末可发稿
					is_pub_image: this.form.is_pub_image, //是否可加配图
					is_pub_video: this.form.is_pub_video, //可加视频
					stop_draft_time: this.form.timer.id, //截稿时间
					pub_url_image: this.form.pub_url_image, //文字链截图
				}).then((res) => {
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '已修改',
						})
						return this.$router.push('/user/PublishingMedia/pubNews')
					} else {
						return this.$message({
							type: 'warning',
							message: res.data.msg,
						})
					}
				})
			})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 添加平台
		addPlat() {
			this.curlPost('api/users/medium/apply_portal', {
				category_id: 1,
				title: this.gatewayForm.gatewayTitle,
				url: `http://${this.gatewayForm.gatewayUrl}`,
			}).then((res) => {
				if (res.data.code) {
					this.addbox = false
					return this.$message({
						type: 'success',
						message: '已上传,待审核',
					})
				} else {
					return this.$message({
						type: 'warning',
						message: res.data.msg,
					})
				}
			})
		},
		mediumUpload() {
			this.mediumBox = true
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/AddResource.scss';
</style>