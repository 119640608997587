<template>
	<div class="form_ct_box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="flex cofirm_box">
			<button class="cancel" @click="$router.push('/user/PublishingMedia/pubShortVideoMedia')">
				取消
			</button>
			<button v-if="!$route.query.id" class="affirm" @click="addMedia">
				确认
			</button>
			<button v-else class="affirm" @click="updateFn">修改</button>
		</div>
		<div class="flex ct_box">
			<div class="ct_left_box">
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">平台类型：</p>
					<el-select v-model="form.gateway.title" @change="gatewayFn" placeholder="请选择所属平台">
						<el-option v-for="(item, index) in gatewayList" :key="index" :value="item.title">
						</el-option>
					</el-select>
					<p class="add_title" v-show="!$route.query.id" @click.stop="addbox = true">
						还没有所属平台,立即添加
					</p>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">媒介名称：</p>
					<el-input v-model="form.title" placeholder="请输入媒介名称"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">抖音号/快手号：</p>
					<el-input v-model="form.account" placeholder="请输入抖音号/快手号"></el-input>
				</div>
				<upLoadImg title="媒介logo" :imgUrl="form.logo_reveal" @getImg="getLogo" />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">行业类型：</p>
					<el-select v-model="form.platform.title" @change="plat" placeholder="请选择行业类型">
						<el-option v-for="(item, index) in platList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">所属城市：</p>
					<el-select v-model="form.area.title" @change="areaFn" placeholder="请选择所属城市">
						<el-option v-for="(item, index) in areaList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">媒介价：</p>
					<el-input onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
						oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
						v-model="form.price" placeholder="请输入价格"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">案例链接：</p>
					<el-input v-model="form.link" placeholder="请输入http:/或https:/完整地址"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">粉丝数：</p>
					<el-input v-model="form.fans_num" placeholder="请输入粉丝数量"></el-input>
				</div>
				<upLoadImg title="粉丝数截图" :imgUrl="form.fans_image_reveal" @getImg="getImg" />
			</div>
			<div class="ct_right_box">

				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">受众性别：</p>
					<el-radio v-model="form.audience_sex" :label="0">不限</el-radio>
					<el-radio v-model="form.audience_sex" :label="1">女性粉丝偏多</el-radio>
					<el-radio v-model="form.audience_sex" :label="2">男性粉丝偏多</el-radio>
					<el-radio v-model="form.audience_sex" :label="3">男性粉丝均衡</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">受众年龄：</p>
					<el-radio v-model="form.audience_age" :label="0">不限</el-radio>
					<el-radio v-model="form.audience_age" :label="1">0-17岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="2">18-24岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="3">25-34岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="4">35-44岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="5">44岁以上偏多</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发内链：</p>
					<el-radio v-model="form.is_pub_url" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_url" :label="1">是</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发联系方式：</p>
					<el-radio v-model="form.is_pub_contact" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_contact" :label="1">是</el-radio>
				</div>
				<specialText />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否周末可发：</p>
					<el-radio v-model="form.is_pub_weekend" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_weekend" :label="1">是 </el-radio>
				</div>

				<div class="flex" style="margin-bottom: 2rem;">
					<p class="title_p2">备注：</p>
					<el-input type="textarea" :rows="2" placeholder="有修改或其他问题请填写备注，免经济纠纷" v-model="form.remark">
					</el-input>
				</div>
				<p @click="mediumUpload" class="multiple">
					您有多个媒体?请点击此处
				</p>
			</div>
		</div>

		<el-dialog title="添加平台" :append-to-body="true" :visible.sync="addbox" :show-close="false">
			<el-row :gutter="20">
				<el-col :span="2.8">
					<div class="grid-content bg-purple">
						<p class="gateway_title">平台名称:</p>
					</div>
				</el-col>
				<el-col :span="18.2">
					<div class="grid-content bg-purple">
						<el-input v-model="gatewayForm.gatewayTitle" placeholder="请输入名称"></el-input>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="2.8">
					<div class="grid-content bg-purple">
						<p class="gateway_title">平台链接:</p>
					</div>
				</el-col>
				<el-col :span="18.2">
					<div class="grid-content bg-purple">
						<el-input v-model="gatewayForm.gatewayUrl" placeholder="请输入链接"></el-input>
					</div>
				</el-col>
				<div class="sub_box flex">
					<button class="sub" @click="addPlat">提交</button>
				</div>
			</el-row>
		</el-dialog>
		<!-- 批量上传 -->
		<el-dialog title="批量上传媒体" width="35%" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
			<div style="justify-content: center;align-items: center;text-align: center;" class="flex">
				<el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
					:on-success="Successxlsx" :before-remove="handleRemove">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">点击上传</div>
				</el-upload>
			</div>

			<div class="flex" style="justify-content: center;margin-top: 1rem;">
				<a class="multiple_a" target="_blank" href="/medium.xlsx">下载模板</a>

				<button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	watch: {
		AllcheckNum(newValue, oldValue) {
			console.log(this.AllcheckNum)
			if (newValue == this.limit) {
				this.Allcheck = true
			} else {
				this.Allcheck = false
			}
		},
	},
	computed: {
		...mapState(['active']),
	},
	mounted() {
		this.$store.state.active = 7
		this.curlGet('/api/medium/portal', {
		}).then((res) => {
			// console.log(res);
			if (res.data.code) {
				if (res.data.code) {
					for (const i in res.data.data[this.$store.state.active]) {
						this.gatewayList.push({
							id: i,
							title: res.data.data[this.$store.state.active][i],
						})
					}
				}
			}
		})
		// 请求行业类型列表
		this.curlGet('/api/medium/industry').then((res) => {
			// console.log(res);
			if (res.data.code) {
				// console.log(this.$store.state.active);
				for (const i in res.data.data[this.$store.state.active]) {
					this.platList.push({
						id: i,
						title: res.data.data[this.$store.state.active][i],
					})
				}
			}
		})
		this.curlGet('/api/area/pid_list').then((res) => {
			console.log(res)
			if (res.data.code) {
				// console.log(this.$store.state.active);
				for (const i in res.data.data) {
					this.areaList.push({
						id: i,
						title: res.data.data[i],
					})
				}
			}
		})
		if (this.$route.query.id) {
			this.curlGet('/api/users/medium/detail', {
				id: this.$route.query.id,
			}).then((res) => {
				if (res.data.code) {
					let data = res.data.data
					this.gatewayList.forEach((item, index) => {
						if (data.portal_id == item.id) {
							this.form.gateway = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.platList.forEach((item, index) => {
						if (data.industry_classify_id == item.id) {
							this.form.platform = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.areaList.forEach((item, index) => {
						if (data.area_id == item.id) {
							this.form.area = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.form.account = data.account //账户
					this.form.title = data.title
					console.log(this.form.title);
					this.form.logo = data.logo //图片
					this.form.logo_reveal = data.logo //图片
					this.form.link = data.case_url
					this.form.price = data.medium_price //价格
					this.form.fans_num = data.fans_num //粉丝数
					this.form.audience_sex = data.audience_sex //性别
					this.form.remark = data.remark //备注
					this.form.audience_age = data.audience_age//受众年龄
					this.form.is_pub_contact = data.is_pub_contact //可发联系方式
					this.form.is_pub_url = data.is_pub_contact//可发链接
					this.form.is_pub_weekend = data.is_pub_weekend //周末可发
					this.form.fans_image = data.fans_image
					this.form.fans_image_reveal = data.fans_image
				}
			})
		}
	},
	data() {
		return {
			gatewayList: [], //门户类型
			platList: [], //行业类型
			areaList: [], //地区类型
			value: '',
			form: {
				logo: '',
				logo_reveal: '', //logo
				gateway: {}, //门户类型
				platform: {}, //行业
				fans_num: '', //粉丝数
				audience_sex: 0, //性别
				account: '', //媒介名称
				title: '',
				area: {}, //地址
				price: undefined, //价格
				remark: '', // 备注
				link: '', //链接
				audience_age: 0,//受众年龄
				is_pub_contact: 0, //可发联系方式
				is_pub_url: 0,//可发链接
				is_pub_weekend: 0,
				mediaUrl: '',
				fans_image: '',
				fans_image_reveal: '', //粉丝数截图
			},
			headerObj: {
				token: this.$user_info.token,
			},
			fullscreenLoading: false, //加载
			addbox: false,
			gatewayForm: {
				gatewayTitle: '',
				gatewayUrl: '',
			},
			addbox: false,
			mediumBox: false,
		}
	},
	methods: {
		getLogo(url, fullurl) {
			this.form.logo = url
			this.form.logo_reveal = fullurl
		},
		getImg(url, fullurl) {
			this.form.fans_image = url
			this.form.fans_image_reveal = fullurl
		},
		mediumUpload() {
			this.mediumBox = true
		},
		// 批量上传媒体
		Successxlsx(res, file) {
			console.log(res, file)
			this.form.mediaUrl = res.data.url
		},
		upLoadXlsx() {
			this.curlGet('/api/users/medium/import', {
				file: this.form.mediaUrl
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					this.$message({
						message: '上传成功,待审核',
						type: 'success'
					});
					this.form.mediaUrl = ''
					return this.mediumBox = false
				} else {
					this.$message.error('上传失败');
				}
			})
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.form.mediaUrl = ''
		},
		handleAvatarSuccess(res, file) {
			console.log(res)
			this.fullscreenLoading = true
			this.dialogImageUrl = res.data.fullurl

			this.imageUrl = URL.createObjectURL(file.raw)
			setTimeout(() => {
				this.fullscreenLoading = false
			}, 500)
		},
		beforeAvatarUpload(file) {
			let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

			const extension =
				testmsg === 'jpg' || testmsg === 'gif' || testmsg === 'png'
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!extension) {
				this.$message.error('上传图片类型只能是 JPG,PNG,GIF格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 2MB!')
			}
			return extension && isLt2M
		},
		beforeMove() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.imageUrl = ''
					this.$message({
						type: 'success',
						message: '已删除',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		gatewayFn(val) {
			let resultArr = this.gatewayList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.gateway = resultArr[0]
		},
		// 获取行业id
		plat(val) {
			let resultArr = this.platList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.platform = resultArr[0]
		},
		// 获取地区id
		areaFn(val) {
			let resultArr = this.areaList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.area = resultArr[0]
		},
		// 添加媒体
		addMedia() {
			this.$confirm('确认提交吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/medium/add', {
						category_id: this.$store.state.active, // 导航id
						portal_id: this.form.gateway.id, // 门户(所属平台)类型
						account: this.form.account, // 标题
						title: this.form.title,
						fans_num: this.form.fans_num, //粉丝数
						medium_price: this.form.price, // 价格
						case_url: this.form.link, // 案例链接
						industry_classify_id: this.form.platform.id, //媒体行业类型
						area_id: this.form.area.id, //所在地区
						audience_sex: this.form.audience_sex, // 性别
						remark: this.form.remark, //备注
						logo: this.form.logo, //logo
						audience_age: this.form.audience_age,//受众年龄
						is_pub_contact: this.form.is_pub_contact, //可发联系方式
						is_pub_url: this.form.is_pub_url, //可发链接
						is_pub_weekend: this.form.is_pub_weekend,//周末可发
						fans_image: this.form.fans_image
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: res.data.msg,
							})
							return this.$router.push('/user/PublishingMedia/pubShortVideoMedia')
						} else {
							return this.$message({
								type: 'warning',
								message: res.data.msg,
							})
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 修改媒体
		updateFn() {
			this.$confirm('确认提交吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/medium/add', {
						id: this.$route.query.id,
						category_id: this.$store.state.active, // 导航id
						portal_id: this.form.gateway.id, // 门户(所属平台)类型
						account: this.form.account, // 标题
						title: this.form.title,
						fans_num: this.form.fans_num, //粉丝数
						medium_price: this.form.price, // 价格
						case_url: this.form.link, // 案例链接
						industry_classify_id: this.form.platform.id, //媒体行业类型
						area_id: this.form.area.id, //所在地区
						audience_sex: this.form.audience_sex, // 性别
						remark: this.form.remark, //备注
						logo: this.imageUrl, //logo
						audience_age: this.form.audience_age,//受众年龄
						is_pub_contact: this.form.is_pub_contact, //可发联系方式
						is_pub_url: this.form.is_pub_url, //可发链接
						is_pub_weekend: this.form.is_pub_weekend,//周末可发
						fans_image: this.form.fans_image
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: res.data.msg,
							})
							return this.$router.push('/user/PublishingMedia/pubShortVideoMedia')
						} else {
							return this.$message({
								type: 'warning',
								message: res.data.msg,
							})
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 添加平台
		addPlat() {
			this.curlPost('api/users/medium/apply_portal', {
				category_id: 2,
				title: this.gatewayForm.gatewayTitle,
				url: `http://${this.gatewayForm.gatewayUrl}`,
			}).then((res) => {
				if (res.data.code) {
					this.addbox = false
					return this.$message({
						type: 'success',
						message: '已上传,待审核',
					})
				} else {
					return this.$message({
						type: 'warning',
						message: res.data.msg,
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/AddResource.scss';
</style>