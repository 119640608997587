<template>
    <div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
            <p class="title_p">{{ title }}：</p>
            <el-upload v-if="!imgUrl" class="avatar-uploader" @submit="uploadFn()" action="/api/common/upload"
                :headers="headers" accept=".jpg,.png" :show-file-list="false" :limit="1"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="flex upload_img" v-if="imgUrl">
                <img :src="imgUrl" alt="" style="width: 120px" />
                <!-- 删除图标 -->
                <span class="delete_icon el-icon-delete" @click="beforeMove"></span>
            </div>

            <p style="margin: 4rem 0 0 2rem" v-if="!imgUrl">jpg、png、gif</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        imgUrl: String
    },
    mounted() {
    },
    data() {
        return {
            fullscreenLoading: false,
            // imgUrl: '',
            headers: {
                token: this.$user_info.token,
            },
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.form.mediaUrl = ''
        },
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading = true
            this.$emit('getImg', res.data.url,res.data.fullurl)
            // this.imgUrl = URL.createObjectURL(file.raw)
            this.fullscreenLoading = false
        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

            const extension =
                testmsg === 'jpg' || testmsg === 'png'
            const isLt2M = file.size / 1024 / 1024 < 5

            if (!extension) {
                this.$message.error('上传图片类型只能是 JPG,PNG格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!')
            }
            return extension && isLt2M
        },
        beforeMove() {
            this.$confirm('是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$emit('getImg', '')
                })
        },
    },
}
</script>

<style lang=scss scoped>
.title_p {
    text-align: right;
    width: 100px;
    font-size: 1.33rem;
}

.title_p2 {
    text-align: right;
    // width: 130px;

    font-size: 1.22rem;
}

::v-deep .el-upload--picture-card {
    width: 148px;
    height: 148px;
}

.upload_img {
    cursor: pointer;
    position: relative;

    &:hover {
        .delete_icon {
            opacity: 1;
        }
    }

    .delete_icon {
        opacity: 0;
        transition: 0.2s;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 2rem;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 9.58rem;
    height: 9.58rem;
    display: block;
}

.avatar-uploader-icon {
    border: 0.17rem dashed #c5e6fc !important;
    width: 90px;
    height: 90px;
    line-height: 90px;
}
</style>